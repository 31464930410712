<template>
    <section class="pl-2 pt-2 overflow-auto custom-scroll" style="height:calc(100vh - 111px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 pl-xl-5 pl-lg-3 pl-md-2 pl-sm-2 pl-2 title-category mb-2">
            <div class="col-12 col-md-8 col-lg-8">
                <p class="col px-0 f-28 text-general f-600">
                    {{ categoria.nombre }}
                </p>
                <div class="col-12" />
                <div class="like-bar mt-2" />
            </div>
            <!-- <div class="col-4">
                <div class="row">
                    <p class="mt-2">Ordenar por</p>
                    <div class="col-8">
                        <el-select v-model="ordenar" placeholder="Opciones" @change="reLoadView">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row mx-0 pl-xl-5 pl-lg-3 pl-md-2 pl-sm-2 pl-2 mb-4">
            <div class="col-12 col-md-8 col-lg-8">
                <p class="col px-0 f-13 text-general text-justify">
                    {{ categoria.descripcion }}
                </p>
            </div>
        </div>
        <tabs :tabs="tabs" />
        <div class="row mx-0 pl-xl-5 pl-lg-3 pl-md-2 pl-sm-2 pl-2 title-category">
            <div v-infinite-scroll="getProducts" infinite-scroll-disabled="stop" infinite-scroll-immediate-check="false" infinite-scroll-distance="20">
                <div class="row mx-0 mt-3 justify-content-xl-none justify-content-lg-start justify-content-md-start justify-content-sm-around justify-content-around pl-xl-5 pl-lg-3 pl-md-2 pl-sm-2 pl-2 pr-xl-5 pr-lg-3 pr-md-2 pr-sm-2 pr-2">
                    <invitado-producto v-for="(data, index) in productos" :key="index" :producto="data" class="mx-xl-4 mx-lg-3 mx-md-3 mx-sm-2 mx-2 mt-2" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { mapGetters } from 'vuex'
import Service from '~/services/invitado/invitado'

export default {
    directives: {infiniteScroll},
    data(){
        return{
            cargando: false,
            stop: false,
            page: 0,
            categoria: {},
            productos: [],
            subCategorias:[],

            ordenar:5,
            options: [{
                value: 1,
                label: 'Precio, Menor a mayor'
            }, {
                value: 2,
                label: 'Precio, Mayor a menor'
            }, {
                value: 3,
                label: 'Nombre, A a Z'
            }, {
                value: 4,
                label: 'Nombre, Z a A'
            }, {
                value: 5,
                label: 'Relevancia'
            }],
        }
    },
    computed:{
        ...mapGetters({
            tienda: 'invitado/tienda',
        }),
        idCategoria(){
            return Number(this.$route.params.idCategoria);
        },
        idSubCategoria(){
            return Number(this.$route.params.idSubCategoria);
        },
        tabs(){
            return this.subCategorias
        },
        routePath(){
            return this.$route.path
        }
    },

    watch: {
        idCategoria(){
            this.changeCategory();
        },
        routePath(){
            this.reLoadView();
        }
    },

    mounted(){
        this.getSubcategorias()
        this.getProducts();
    },

    methods: {
        async getSubcategorias(){
            try {
                if(!this.tienda.id_tienda){
                    await this.$store.dispatch('invitado/getTiendaDefault')
                }

                const params = {
                    idTienda:this.tienda.id_tienda
                }
                
                const {data} = await Service.getSubcategorias(this.idCategoria, params)
                if(!data){
                    this.router.push({name:'invitado'})
                    return
                }
                if(data.subCategorias.length < 1){
                    this.router.push({name:'invitado'})
                    return
                }

                this.categoria = data.categoria
                const todos = { nombre: 'Todos', ruta: `/invitado/categoria/${this.idCategoria}/ver`}
                this.subCategorias.push(todos,...data.subCategorias)

            } catch (error){
                this.errorCatch(error)   
            }
        },
        async getProducts(){
            try {
                if(this.stop)return;

                this.cargando = true;

                this.page = this.page + 1;
                this.stop = true;

                const idCategoria = isNaN(this.idSubCategoria) ? this.idCategoria : this.idSubCategoria
                const params = {
                    idTienda:this.tienda.id_tienda,
                    page: this.page,
                    orden: this.ordenar
                };

                const {data} = await Service.getProductosCategoria(idCategoria, params);
                
                this.productos = [...this.productos, ...data.productos.data];

                if(data.productos.current_page === data.productos.last_page){
                    this.stop = true;
                }else{
                    this.stop = false;
                }
            } catch(e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }
        },
        async changeCategory(){
            this.page = 0;
            this.stop = false;
            this.productos = [];
            this.subCategorias = []
            this.getSubcategorias()
        },
        async reLoadView(){
            this.page = 0;
            this.stop = false;
            this.productos = [];
            this.getProducts();
        },
    },
}
</script>
<style lang="scss" scoped>
.like-bar{
    width: 44px;
    height: 5px;
    background-color: var(--color-general);
}
</style>